<template>
	<div class="user__view">
		<div class="header">
			<h2>Modifier le thème</h2>
			<v-btn color="white elevation-0" :loading="loadingDelete" class="white--text" @click="dialog = true; toDelete = theme" icon>
				<v-icon color="red lighten-1">
					mdi-trash-can-outline
				</v-icon>				
			</v-btn>
			<v-dialog v-model="dialog" width="300">
                <v-card>
                    <v-card-text style="padding-top: 20px">
                        <h3 class="text-center">Supprimer le thème ?</h3>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" text @click="dialog = false">
                            Annuler
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-3" :loading="loadingDelete" text @click="deleteTheme()">
                            Supprimer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
		</div>
		<div class="control">
			<v-btn color="#272727" class="white--text" @click="$router.push({path: '/theme'})" tile x-large>
				Retour
			</v-btn>
			<v-btn color="green" class="white--text" :loading="loading" @click="validate()" tile x-large >
				Sauvegarder
			</v-btn>
		</div>
		<div class="displayer">
			<div class="right">
				<div class="user__card">
					<div class="theme">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<v-text-field v-model="theme.name" :rules="nomRules" label="Nom"></v-text-field>
						</v-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../utils.js'

export default {
	name: 'UserAdd',
	data() {
		return {
			valid: false,
			nomRules: [
				v => !!v || 'Champs requis'
			],
			theme: {
				id: null,
				name: ''
			},
			toDelete: {},
			dialog: false,
			loading: false,
			loadingDelete: false
		}
	},
	methods: {
		...mapActions("global", [
			"getThemeAction",
			"updateThemeAction",
			"deleteThemeAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		validate () {
			if (this.$refs.form.validate() == false) {
				return
			}
			this.saveTheme()
		},
		saveTheme() {
			const _this = this;
			this.loading = true;

			this.updateThemeAction({
				theme: this.$route.params.themeId,
				name: this.theme.name
			})
			.then(() => {
				_this.addSuccess("Le thème a été modifié.")
				_this.$router.push({ path: '/theme' });
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		deleteTheme() {
			const _this = this;
			this.loadingDelete = true;
			this.deleteThemeAction(this.toDelete.id)
			.then(() => {
				_this.addSuccess("Le thème a été supprimé.")
				this.$router.push({path: '/theme'})
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loadingDelete = false
				_this.toDelete = {}
				_this.dialog = false
			})
		},
		loadTheme() {
			const _this = this
			this.getThemeAction(this.$route.params.themeId)
			.then(res => {
				_this.theme.id = res.data.id
				_this.theme.name = res.data.name
			})
		}
	},
	created() {
		this.loadTheme()
	}
}
</script>

<style lang="scss" scoped>
.user__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.user__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.user {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>